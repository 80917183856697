<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <v-col
        cols="12"
        md="5"
      >
        <div class="d-flex flex-column justify-space-between align-center">
          <span class="text-h3 font-weight-bold text-center teal--text">
            INFORMACIÓN DEL AÑO {{ idJuzgado.split('_')[2] }}
          </span>
          <span class="text-h4 text-center">
            <v-icon @click="irInicio()">
              mdi-home
            </v-icon> / <v-icon
              small
            >
              mdi-forward
            </v-icon> / {{ dataJuzgado.nombre }}
          </span>
        </div>
        <h4
          v-if="dataJuzgado.restriccion"
          class="card-title text-center mt-2 red--text"
        >
          {{ dataJuzgado.restriccion }}
        </h4>
        <div
          v-if="parseInt(currentYear) === parseInt(idJuzgado.split('_')[2])"
          class="d-flex flex-column align-center"
        >
          <span
            v-if="juez1"
            class="text-h4 font-weight-bold text-center"
          >
            {{ juez2 !== '' ? 'JUECES' : 'JUEZ' }}
          </span>
          <ul
            v-if="juez1"
            class="text-center"
          >
            <v-icon
              color="red"
            >
              mdi-card-account-details-outline
            </v-icon>
            <strong
              class="red--text"
              @click="irJuez(idJuzgado.split('_')[0])"
            > CLIC AQUÍ PARA CONOCER MÁS SOBRE {{ juez2 !== '' ? 'LOS JUECES' : 'EL JUEZ' }}</strong>
          </ul>
          <ul
            v-if="juez1"
            class="text-h4 font-weight-bold text-center"
          >
            {{ juez1 }}
          </ul>
          <ul
            v-if="juez2"
            class="text-h4 font-weight-bold text-center"
          >
            {{ juez2 }}
          </ul>
          <ul
            v-if="juez3"
            class="text-h4 font-weight-bold text-center"
          >
            {{ juez3 }}
          </ul>
        </div>
        <base-material-chart-card
          :data="produccionJuzgado.data"
          :options="produccionJuzgado.options"
          color="teal"
          hover-reveal
          type="Line"
        >
          <h4 class="card-title mt-2 text-center">
            RESUMEN DE PRODUCCIÓN MENSUAL
          </h4>

          <div
            class="d-flex flex-column align-center"
          >
            <v-col
              cols="12"
              md="12"
            >
              <!-- <span
                v-for="(item, index) in juzgadosData"
                :key="item.mes"
                class="teal--text"
              >
                <span v-if="produccionMensual[index] >0">{{ meses[index] }}: <strong>{{ produccionMensual[index] }}</strong> expedientes resueltos<br></span>
              </span> -->
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="primary--text">
                      Mes
                    </th>
                    <th class="primary--text text-center">
                      Cantidad (exp. resueltos)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in juzgadosData"
                    :key="item.mes"
                  >
                    <td class="primary--text">
                      {{ meses[item.mes - 1] }}
                    </td>
                    <td
                      class="text-center font-weight-bold teal--text"
                    >
                      <strong>{{ produccionMensual[index] }}</strong>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </div>
          <template v-slot:actions>
            <span
              v-if="dataJuzgado.mensual"
              class="text-caption grey--text"
            >
              <v-icon>mdi-flag</v-icon><span><strong>Meta mensual: {{ dataJuzgado.mensual }}</strong></span>
              <pre class="text-caption grey--text font-weight-light">*Vacaciones judiciales</pre>
            </span>
          </template>
        </base-material-chart-card>
      </v-col>
      <v-col
        v-if="dataJuzgado.anual > 0"
        cols="12"
        md="9"
      >
        <base-material-card
          icon="mdi-poll"
          color="teal"
          title="cumplimiento de meta"
          class="px-5 py-3"
        >
          <h4
            v-if="dataJuzgado.restriccion"
            class="card-title mt-2 red--text"
          >
            {{ dataJuzgado.restriccion }}
          </h4>
          <h4 class="d-inline-flex mt-1">
            Meta anual: {{ dataJuzgado.anual }}**
          </h4><br>
          <h4
            v-if="parseInt(dataJuzgado.enero) === 0"
            class="d-inline-flex mt-1"
          >
            Meta anual proyectada: {{ parseInt(dataJuzgado.meta_anual) }}**
          </h4>
          <h5 class="d-inline-flex font-weight-light mt-1">
            IMPORTANTE: Las metas de producción son aprobadas anualmente por el Consejo Ejecutivo del Poder Judicial, computándose únicamente las resoluciones que ponen fin a la instancia, excluyendo todas las demás resoluciones emitidas por los Jueces, como las medidas cautelares y de ejecución, entre otros.
            <br> **Las metas son preeliminares y ajustables
          </h5>
          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text">
                  Mes
                </th>
                <th class="primary--text text-center">
                  Meta mensual
                </th>
                <th class="primary--text text-center">
                  Expedientes resueltos
                </th>
                <th class="primary--text text-center">
                  Cumplimiento de meta
                </th>
                <th class="primary--text text-center">
                  Avance de meta
                </th>
                <th
                  v-if="parseInt(dataJuzgado.enero) === 0"
                  class="primary--text text-center"
                >
                  Avance de meta proyectada*
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in juzgadosData"
                :key="item.mes"
              >
                <td
                  class="primary--text font-weight-bold"
                >
                  {{ meses[item.mes - 1] }}
                </td>
                <td
                  class="text-center"
                >
                  {{ parseInt(item.mes) === 2 ? '0' : produccionMensual[index] === 0 ? '0' : dataJuzgado.mensual }}
                </td>
                <td
                  class="text-center"
                >
                  {{ produccionMensual[index] }}
                </td>
                <td
                  class="text-center"
                >
                  <v-chip
                    label
                  >
                    <strong>{{ parseInt(item.mes) === 2 ? produccionMensual[index] : produccionMensual[index] === 0 ? '0' : (produccionMensual[index] - dataJuzgado.mensual) }}</strong>
                  </v-chip>
                </td>
                <td
                  class="text-center"
                >
                  {{ ((( sumatoriaMes[index]) / dataJuzgado.anual) * 100).toFixed(2) }}%
                </td>
                <td
                  v-if="parseInt(dataJuzgado.enero) === 0"
                  class="text-center"
                >
                  {{ ((( sumatoriaMes[index]) / dataJuzgado.meta_anual) * 100).toFixed(2) }}%
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Acumulado
                </td>
                <td class="text-center font-weight-bold">
                  {{ juzgadosData.length != 1 ? parseInt(dataJuzgado.mensual) * ((juzgadosData.length) - mes0) : parseInt(dataJuzgado.mensual) * juzgadosData.length }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoria }}
                </td>
                <td class="text-center font-weight-bold">
                  <v-chip
                    v-if="juzgadosData.length!==1"
                    class="ma-2"
                    label
                  >
                    <strong>{{ sumatoria - (dataJuzgado.mensual * ((juzgadosData.length) - mes0)) }}</strong>
                  </v-chip>
                  <v-chip
                    v-else
                    class="ma-2"
                    label
                  >
                    <strong>{{ sumatoria - (dataJuzgado.mensual * (juzgadosData.length)) }}</strong>
                  </v-chip>
                </td>
                <td class="text-center font-weight-bold">
                  {{ ((sumatoria / (dataJuzgado.anual)) * 100).toFixed(2) }}%
                </td>
                <td
                  v-if="parseInt(dataJuzgado.enero) === 0"
                  class="text-center font-weight-bold"
                >
                  {{ ((sumatoria / (dataJuzgado.meta_anual)) * 100).toFixed(2) }}%
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <template v-slot:actions>
            <span
              class="text-caption grey--text font-weight-light"
            >
              <pre class="text-caption grey--text font-weight-bold">*Vacaciones judiciales</pre>
              <!-- <pre class="text-caption grey--text font-weight-bold">**Las metas son preeliminares y ajustables</pre> -->
              <!-- <span><span
                      class="text-caption green--text font-weight-bold"
                    >Verde: </span>producción positiva con respecto a la meta <br>
                <span
                  class="text-caption yellow--text font-weight-bold"
                >Amarillo:</span> producción negativa hasta 9.1% con respecto a la meta<br>
                <span
                  class="text-caption red--text font-weight-bold"
                >Rojo: </span> producción negativa mayor a 9.1% con respecto a la meta
              </span> -->

            </span>
          </template>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <base-material-card
          icon="mdi-gavel"
          color="teal"
          title="DETALLE DE PRODUCCIÓN"
          class="px-5 py-3"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text">
                  Mes
                </th>
                <th class="primary--text text-center">
                  Sentencias
                </th>
                <th class="primary--text text-center">
                  Autos
                </th>
                <th
                  v-if="parseInt(dataJuzgado.es_sala) === 0"
                  class="primary--text text-center"
                >
                  Conciliación
                </th>
                <th class="primary--text text-center">
                  Demanda improcedente
                </th>
                <th class="primary--text text-center">
                  Apelaciones / Confirma
                </th>
                <th class="primary--text text-center">
                  Apelaciones / Revoca
                </th>
                <th class="primary--text text-center">
                  Apelaciones / Anula
                </th>
                <th class="primary--text text-center">
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in juzgadosData"
                :key="item.mes"
              >
                <td
                  class="primary--text font-weight-bold"
                >
                  {{ meses[item.mes - 1] }}
                </td>
                <td
                  class="text-center"
                >
                  {{ juzgadosData[index].sentencia }}
                </td>
                <td
                  class="text-center"
                >
                  {{ juzgadosData[index].auto }}
                </td>
                <td
                  v-if="parseInt(dataJuzgado.es_sala) === 0"
                  class="text-center"
                >
                  {{ juzgadosData[index].conciliacion }}
                </td>
                <td
                  class="text-center"
                >
                  {{ juzgadosData[index].demanda_improcedente }}
                </td>
                <td
                  class="text-center"
                >
                  {{ juzgadosData[index].apela_confirmada }}
                </td>
                <td
                  class="text-center"
                >
                  {{ juzgadosData[index].apela_revocada }}
                </td>
                <td
                  class="text-center"
                >
                  {{ juzgadosData[index].apela_anulada }}
                </td>
                <td
                  class="text-center"
                >
                  {{ produccionMensual[index] }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Acumulado
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoriaSentencias }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoriaAutos }}
                </td>
                <td
                  v-if="parseInt(dataJuzgado.es_sala) === 0"
                  class="text-center font-weight-bold"
                >
                  {{ sumatoriaConciliados }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoriaImprocedente }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoriaConfirma }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoriaRevoca }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoriaAnula }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ sumatoria }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <template v-slot:actions>
            <span
              class="text-caption grey--text font-weight-light"
            >
              <span>
                *Vacaciones judiciales
              </span>
            </span>
          </template>
        </base-material-card>
      </v-col>
    <!--   <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          icon="mdi-marker-check"
          color="gris"
          title="Seguimiento de resoluciones judiciales"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text">
                  Mes
                </th>
                <th class="primary--text">
                  Confirmadas
                </th>
                <th class="primary--text">
                  Revocadas
                </th>
                <th class="primary--text">
                  Anuladas
                </th>
                <th class="primary--text">
                  Porcentaje de calidad
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in juzgadosData"
                :key="item.mes"
              >
                <td v-if="produccionMensual[index] >0">
                  {{ meses[index] }}
                </td>
                <td v-if="produccionMensual[index] >0">
                  {{ juzgadosData[index].calidad_confirmada }}
                </td>
                <td v-if="produccionMensual[index] >0">
                  {{ juzgadosData[index].calidad_revocada }}
                </td>
                <td v-if="produccionMensual[index] >0">
                  {{ juzgadosData[index].calidad_anulada }}
                </td>
                <td v-if="produccionMensual[index] >0">
                  {{ isNaN((parseInt(juzgadosData[index].calidad_confirmada) / (parseInt(juzgadosData[index].calidad_confirmada) + parseInt(juzgadosData[index].calidad_revocada) + parseInt(juzgadosData[index].calidad_anulada)) * 100).toFixed(2)) ? '0.00' : (parseInt(juzgadosData[index].calidad_confirmada) / (parseInt(juzgadosData[index].calidad_confirmada) + parseInt(juzgadosData[index].calidad_revocada) + parseInt(juzgadosData[index].calidad_anulada)) * 100).toFixed(2) }}%
                </td>
              </tr>
              <tr>
                <td>Acumulado</td>
                <td>{{ isNaN(sumatoriaConfirmadas) ? 0 : sumatoriaConfirmadas }}</td>
                <td>{{ isNaN(sumatoriaRevocadas) ? 0 : sumatoriaRevocadas }}</td>
                <td>{{ isNaN(sumatoriaAnuladas) ? 0 : sumatoriaAnuladas }}</td>
                <td>{{ isNaN(sumatoriaConfirmadas) ? 0 : (isNaN(sumatoriaConfirmadas / (sumatoriaRevocadas + sumatoriaAnuladas + sumatoriaConfirmadas)) ? 0 : (sumatoriaConfirmadas / (sumatoriaRevocadas + sumatoriaAnuladas + sumatoriaConfirmadas) * 100).toFixed(2)) }}%</td>
              </tr>
            </tbody>
          </v-simple-table>
        </base-material-card>
      </v-col> -->
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="3"
      >
        <v-combobox
          v-model="anoSeleccionado"
          :items="anosAnteriores"
          label="Consulte producción de años anteriores"
        />
      </v-col>
      <v-btn
        color="primary"
        small
        @click="irJuzgadoAno(anoSeleccionado)"
      >
        CONSULTAR
        <v-icon right>
          mdi-clock
        </v-icon>
      </v-btn>
    </v-row>
    <v-col
      cols="12"
      md="auto"
    >
      <div class="text-body-1 font-weight-light text-center">
        {{ new Date().getFullYear() }} &copy; CSJLA - <v-icon>mdi-space-invaders</v-icon>
      </div>
    </v-col>
    <br>
    <v-row
      justify="center"
    >
      <v-btn
        color="primary"
        depressed
        @click="irInicio()"
      >
        INICIO
        <v-icon right>
          mdi-home-circle
        </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Produccion',
    data () {
      return {
        headers: [
          {
            text: 'Meta mensual',
            value: 'meta_mensual',
          },
          {
            text: 'Expedientes resueltos',
            value: 'm1',
          },
          {
            text: 'Cumplimiento de meta',
            value: 'estado',
          },
          {
            text: 'Avance de meta',
            value: 'tipo',
          },
        ],
        items: [],
        carga: true,
        produccion_juzgado: [],
        meses: ['Enero', 'Febrero*', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        calidad_juzgado: [],
        var_m: '',
        calidad: false,
        juzgadosData: [],
        search: '',
        prod_data: [],
        mes_graph: [],
        prod_mes: '0',
        balance: 0,
        sumatoria: 0,
        jueces: [],
        juez: '',
        juez1: '',
        juez2: '',
        juez3: '',
        sumatoriaConfirmadas: 0,
        sumatoriaRevocadas: 0,
        sumatoriaAnuladas: 0,
        sumatoriaSentencias: 0,
        sumatoriaAutos: 0,
        sumatoriaConciliados: 0,
        sumatoriaImprocedente: 0,
        sumatoriaConfirma: 0,
        sumatoriaRevoca: 0,
        sumatoriaAnula: 0,
        i: 0,
        prod_sum: '0',
        meta_mes: '0',
        meta_ano: '0',
        colorsum: 'green',
        balance_mes: '0',
        balance_color: 'primary',
        url: '',
        urlJuzgado: '',
        urlLogo: '',
        dataSede: '',
        urlSede: '',
        produccionMensual: [],
        sentenciasMensual: [],
        autosMensual: [],
        conciliadosMensual: [],
        improcedenteMensual: [],
        confirmadosMensual: [],
        revocadosMensual: [],
        anuladosMensual: [],
        dataJuzgado: '',
        anosAnteriores: [],
        anoSeleccionado: '',
        idJuzgado: '',
        valorMaximo: '',
        sumaMensual: 0,
        sumatoriaMes: [],
        currentYear: '',
        mes0: 0,
        itemsB: [
          {
            text: '',
            disabled: true,
          },
        ],
        produccionJuzgado: {
          data: {
            // labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'],
            labels: [],
            series: [
              [0],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 200, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 5,
              left: 0,
            },
          },
        },
      }
    },
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', true)
      this.$store.commit('SET_LOGO', true)
      var urlJ = window.location.href.split('/')
      this.idJuzgado = urlJ[urlJ.length - 2]
      this.sedeCorteData = this.idJuzgado.split('_')[1]
      this.urlAnos = `${window.__env.dataCfg.urlApiData}produccion/anos/`
      // this.urlAnos = `${window.__env.dataCfg.urlApiData}api/produccion/${this.idJuzgado.split('_')[0] + '_' + this.idJuzgado.split('_')[1]}/`
      this.url = `${window.__env.dataCfg.urlApiData}produccion/${this.idJuzgado}/`
      this.urlJuzgado = `${window.__env.dataCfg.urlApiData}juzgado/${this.idJuzgado}/`
      this.urlDataSede = `${window.__env.dataCfg.urlApiData}sedes/`
      this.leerDataSede()
      window.scrollTo(0, 0)
      this.currentYear = new Date().getFullYear()
      // this.anosAnteriores.push(this.currentYear, this.currentYear - 1, this.currentYear - 2, this.currentYear - 3)
    },
    methods: {
      leerDataSede () {
        axios
          .get(
            this.urlDataSede,
          )
          .then((response) => {
            this.dataSede = response.data.array.filter(item => item.id === this.sedeCorteData)
            this.urlLogo = 'assets/' + this.dataSede[0].logo
            this.leerInfoJuzgado()
            // this.dataSede = Object.values(response.data).filter(item => item.id === this.sedeCorteData)
            // console.log(Object.values(response.data))
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      irJuez (cod) {
        window.open(`https://csjla.pe/judgedata?judged=${cod}`)
      },
      leerAnos () {
        axios
          .get(this.urlAnos)
          .then(response => {
            response.data.array.forEach(element => this.anosAnteriores.push(element.ano_meta))
          })
          .catch(e => {
            console.log(e) //eslint-disable-line
          })
      },
      leerInfoJuzgado () {
        axios
          .get(this.urlJuzgado)
          .then(response => {
            console.log(response)
            if (response.data.resp === false) {
              this.carga = false
              alert('NO SE HA ENCONTRADO INFORMACIÓN SOBRE LA PRODUCCIÓN DEL JUZGADO')
            } else {
              this.dataJuzgado = response.data.array[0]
              if (parseInt(this.dataJuzgado.estado) === 0) {
                alert('EL JUZGADO ESTÁ DESACTIVADO')
              }
              this.leerJuzgadoData()
            }
          })
          .catch(e => {
            console.log(e) //eslint-disable-line
          })
      },
      leerJuzgadoData () {
        /* console.log(this.idJuzgado)
        const data = {
          n_anio_est: this.idJuzgado.split('_')[2],
          n_dependencia: this.idJuzgado.split('_')[0],
        }
        axios
          .post('http://170.81.242.92:3005/produccion/produccionAnual', data)
          .then(r => {
            console.log(r)
          }).catch(e => {
            console.log(e) //eslint-disable-line
          }) */
        axios.get(this.url)
          .then(response => {
            if (response.data.resp === false) {
              alert('NO SE ENCONTRÓ INFORMACIÓN DEL JUZGADO')
              this.carga = false
            } else {
              this.juzgadosData = response.data.array
              this.juzgadosData.sort(function (a, b) {
                if (parseInt(a.mes) > parseInt(b.mes)) {
                  return 1
                }
                if (parseInt(a.mes) < parseInt(b.mes)) {
                  return -1
                }
                // a must be equal to b
                return 0
              })
              // var resultado = this.juzgadosData.find(juz => juz.idinstancia === 1)
              var resultado = []
              this.items = resultado
              this.produccion_juzgado = resultado
              this.itemsB.push(
                {
                  text: this.dataJuzgado.nombre,
                  disabled: true,
                })
              if (this.dataJuzgado.juez) {
                this.jueces = this.dataJuzgado.juez.split('-')
                if (this.jueces.length === 1) {
                  this.juez1 = this.jueces[0]
                } else if (this.jueces.length === 2) {
                  this.juez1 = this.jueces[0]
                  this.juez2 = this.jueces[1]
                } else if (this.jueces.length === 3) {
                  this.juez1 = this.jueces[0]
                  this.juez2 = this.jueces[1]
                  this.juez3 = this.jueces[2]
                }
              }
              for (var i = 0; i < this.juzgadosData.length; i++) {
                // this.sentenciasMensual[i] = (typeof this.sentenciasMensual[i] === 'undefined') ? 0 : this.sentenciasMensual[i]
                this.produccionMensual[i] = parseInt(this.juzgadosData[i].apela_anulada) + parseInt(this.juzgadosData[i].apela_confirmada) + parseInt(this.juzgadosData[i].apela_revocada) + parseInt(this.juzgadosData[i].auto) + parseInt(this.juzgadosData[i].conciliacion) + parseInt(this.juzgadosData[i].demanda_improcedente) + parseInt(this.juzgadosData[i].sentencia)
                if (parseInt(this.juzgadosData[i].mes) === 2) { // valido si existe mes febrero
                  this.mes0 = 1
                }
                // this.sentenciasMensual[i] = parseInt(this.juzgadosData[i].sentencia) + parseInt(this.sentenciasMensual[i])
                this.sumatoriaSentencias = parseInt(this.juzgadosData[i].sentencia) + parseInt(this.sumatoriaSentencias)
                this.sumatoriaAutos = parseInt(this.juzgadosData[i].auto) + parseInt(this.sumatoriaAutos)
                this.sumatoriaConciliados = parseInt(this.juzgadosData[i].conciliacion) + parseInt(this.sumatoriaConciliados)
                this.sumatoriaImprocedente = parseInt(this.juzgadosData[i].demanda_improcedente) + parseInt(this.sumatoriaImprocedente)
                this.sumatoriaConfirma = parseInt(this.juzgadosData[i].apela_confirmada) + parseInt(this.sumatoriaConfirma)
                this.sumatoriaRevoca = parseInt(this.juzgadosData[i].apela_revocada) + parseInt(this.sumatoriaRevoca)
                this.sumatoriaAnula = parseInt(this.juzgadosData[i].apela_anulada) + parseInt(this.sumatoriaAnula)
                this.sumatoriaConfirmadas = parseInt(this.juzgadosData[i].calidad_confirmada) + parseInt(this.sumatoriaConfirmadas)
                this.sumatoriaRevocadas = parseInt(this.juzgadosData[i].calidad_revocada) + parseInt(this.sumatoriaRevocadas)
                this.sumatoriaAnuladas = parseInt(this.juzgadosData[i].calidad_anulada) + parseInt(this.sumatoriaAnuladas)
                this.sumatoria = this.produccionMensual[i] + this.sumatoria
                this.sumatoriaMes[i] = this.sumatoria
                this.prod_data.push(this.produccionMensual[i])
                this.produccionJuzgado.data.labels.push(this.meses[this.juzgadosData[i].mes - 1].substring(0, 3) + '\n (' + this.produccionMensual[i] + ')')
              }
              if (this.prod_data.length > 5) {
                this.produccionJuzgado.data.labels.slice(Math.max(this.produccionJuzgado.data.labels.length - 5, 0))
                this.produccionJuzgado.data.labels = this.produccionJuzgado.data.labels.slice(1).slice(-5)
                this.prod_data = this.prod_data.slice(1).slice(-5)
              }
              this.produccionJuzgado.data.series = [
                this.prod_data,
              ]
              this.leerAnos()
              this.carga = false
              this.produccionJuzgado.options.high = Math.max(...this.produccionMensual) + 10
            }
          })
          .catch(e => {
            console.log(e) //eslint-disable-line
          })
      },
      colorBalance (prod, meta) {
        let color
        (parseInt(prod) - parseInt(meta) >= 0) ? color = 'green' : (Math.abs(parseInt(prod) - parseInt(meta)) < (parseInt(meta) * 0.091)) ? color = 'yellow' : color = 'red accent-2'
        return color
      },
      irSede () {
        this.$router.push(`/sede/${this.sedeCorteData}/`)
      },
      irInicio () {
        this.$router.push('/')
      },
      irJuzgadoAno (ano) {
        if (ano) {
          this.$router.push(`/prod/${this.idJuzgado.split('_')[0]}_${this.idJuzgado.split('_')[1]}_${ano}/`)
          window.location.reload()
        } else {
          alert('SELECCIONE AÑO')
        }

        /* this.$router.replace(
          { path: `/prod/${this.idJuzgado.split('_')[0]}_${this.idJuzgado.split('_')[1]}_${ano}/` },
          () => {
            this.$router.go(0)
          },
        ) */
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
